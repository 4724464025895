import React from 'react';
import { graphql } from 'gatsby';
import Page from '@components/Page';
import FormBot2 from '@components/FormBot2';
import BackgroundImage from 'gatsby-background-image';

const QUESTIONS = [
  {
    planId: '1',
    start: 'Czy sprzedajesz napoje?',
    questions: [
      'Czy chcesz znać przyczynę różnic między zaplanowanym a rzeczywistym beverage costem % i marżą % sprzedawanych produktów?',
      'Czy chcesz aby proces kontrolny był niezależny od kompetencji Twoich pracowników?',
      'Czy chcesz aby doświadczony konsultant przygotował dla Ciebie raport, omówił raport z Tobą i wskazał "rzeczy do poprawy"?',
      'Czy chcesz otrzymywać zestawienie danych do menu engineeringu dla menu napojów?'
    ],
    followups: [
      [
        'Czy kontrolujesz zużycie surowców w swoim biznesie?',
        'Czy wiesz jaki jest koszt surowców w porównaniu do sprzedaży?',
        'Czy masz wystarczającą ilość danych aby redukować koszty surowców?'
      ],
      [
        'Czy dostajesz od szefa baru gotowy raport?',
        'Czy raport prezentuje rzeczywisty i docelowy beverage cost %/marżę % dla grup produktów?',
        'Czy raport prezentuje rzeczywisty i docelowy beverage cost %/marżę % dla indywidualnych produktów?',
        'Czy samodzielnie analizujesz raport?',
        'Czy omawiasz raport z szefem baru?',
        'Czy po analizie raportu szef baru wskazuje działania których celem jest poprawa wskaźników (BC%/marża%)?'
      ],
      [
        'Czy wymieniasz się doświadczeniami z zakresu metod kontroli kosztów z osobami na zbliżonym stanowisku do Twojego?',
        'Czy podejmujesz długofalowe działania których celem jest zwiększenie Twoich praktycznych umiejętności w zakresie kontroli kosztów surowców?',
        'Czy znasz nowoczesne technologie rozwiązujące problem kontroli kosztu surowców?'
      ],
      [
        'Czy planujesz politykę cenową (ceny w ofercie) na podstawie danych wolumenu sprzedaży (np. z ostatniego miesiąca lub kwartału)?',
        'Czy planujesz politykę cenową (ceny w ofercie) na podstawie aktualnych cen surowców (np. dane z okresu dwóch tygodni do miesiąca)?',
        'Czy na bieżąco weryfikujesz marżowość menu/oferty?'
      ]
    ]
  },
  {
    planId: '2',
    start: 'Czy sprzedajesz jedzenie?',
    questions: [
      'Czy chcesz znać przyczynę różnic między zaplanowanym a rzeczywistym food costem % i marżą % sprzedawanych produktów?',
      'Czy chcesz aby proces kontrolny był niezależny od kompetencji Twoich pracowników?',
      'Czy chcesz aby doświadczony konsultant przygotował dla Ciebie raport, omówił raport z Tobą i wskazał "rzeczy do poprawy"?',
      'Czy chcesz otrzymywać zestawienie danych do menu engineeringu?',
    ],
    followups: [
      [
        'Czy kontrolujesz zużycie surowców w swoim biznesie?',
        'Czy znasz rzeczywisty koszt zużycia surowców w ujęciu miesięcznym? To pytanie może być postawione inaczej: Czy wiesz jaki jest koszt surowców w porównaniu do sprzedaży?',
        'Czy masz wystarczającą ilość danych aby redukować koszty surowców?',
      ],
      [
        'Czy dostajesz od szefa baru gotowy raport?',
        'Czy raport prezentuje rzeczywisty i docelowy beverage cost %/marżę % dla grup produktów?',
        'Czy raport prezentuje rzeczywisty i docelowy beverage cost %/marżę % dla indywidualnych produktów?',
        'Czy samodzielnie analizujesz raport?',
        'Czy omawiasz raport z szefem baru?',
        'Czy po analizie raportu szef baru wskazuje działania których celem jest poprawa wskaźników (FC%/marża%)?',
      ],
      [
        'Czy wymieniasz się doświadczeniami z zakresu metod kontroli kosztów z osobami na zbliżonym stanowisku do Twojego?',
        'Czy podejmujesz długofalowe działania których celem jest zwiększenie Twoich praktycznych umiejętności w zakresie kontroli kosztów surowców?',
        'Czy znasz nowoczesne technologie rozwiązujące problem kontroli kosztu surowców?',
      ],
      [
        'Czy planujesz politykę cenową (ceny w ofercie) na podstawie danych wolumenu sprzedaży (np. z ostatniego miesiąca lub kwartału)?',
        'Czy planujesz politykę cenową (ceny w ofercie) na podstawie aktualnych cen surowców (np. dane z okresu dwóch tygodni do miesiąca)?',
        'Czy na bieżąco weryfikujesz marżowość menu/oferty?',
      ]
    ]
  }
];

const getQuestion = (id, question, conditions = []) => {
  const mappedContitions = conditions.map(
    ([key, positive]) => [
      `cf-conditional-${key}`,
      positive ? 'Tak' : 'Nie'
    ])
  console.log({ mappedContitions });
  const conditionProps = conditions.length ? Object.fromEntries(mappedContitions) : {};
    console.log({conditionProps});
  return {
    tag: 'fieldset',
    id: id,
    'cf-questions': question,
    isMultiChoice: false,
    children: [
      { tag: 'input', type: 'radio', name: id, 'cf-label': 'Tak', value: 'Tak', ...conditionProps },
      { tag: 'input', type: 'radio', name: id, 'cf-label': 'Nie', value: 'Nie', ...conditionProps }
    ]
  }
};

const getBranch = ({ planId, start, questions, followups = [] }) => {
  const tags = [];
  if (start)
    tags.push(getQuestion(`q-${planId}-0`, start));

  questions.forEach((question, i) => {
    const conditions = [];
    if(start && i === 0)
      conditions.push([`q-${planId}-0`, true]);
    else if (i > 0 && followups[i - 1])
      conditions.push([`q-${planId}-${i}-f`, false]);
    else if (i > 0)
      conditions.push([`q-${planId}-${start ? i : i - 1}`, false]);

    tags.push(getQuestion(`q-${planId}-${i + 1}`, question, conditions));

    if (followups[i])
      tags.push(getQuestion(`q-${planId}-${i + 1}-f`, followups[i].join('\n'), [[`q-${planId}-${i + 1}`, false]]));

  });
  return tags;
}

const getTags = () => {
  const tags = [];
  QUESTIONS.forEach(question => {
    tags.push(...getBranch(question));
  });
  console.log({tags});
  return tags;
};

export default function QuizTemplate({ data: { pricingBG, nsds }, pageContext: { language }}) {
  return (
    <Page>
      <BackgroundImage fluid={pricingBG.sharp.fluid} style={{ height: '100vh', width: '100vw' }}>
        <FormBot2 robotImage={nsds.sharp.fixed.src} tags={getTags()} />
      </BackgroundImage>
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    nsds: file(relativePath: { eq: "nsds.png" }) {
      sharp: childImageSharp {
        fixed(width: 176, height: 176) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pricingBG: file(relativePath: { eq: "pricing-bg.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;