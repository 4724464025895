import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { navigate, withPrefix } from 'gatsby';
import { getTagsFromSessionStorage } from '@lib/searchQuery';

export default class FormBot2 extends React.Component {

  componentDidMount() {
    const { robotImage, tags } = this.props;

    const handleUserSubmit = ({ detail }) => {
      console.log({ detail });
    };

    const initializeForm = () => {
      if (!window || !window.cf)
        return false;
      clearInterval(this.intervalId);
      const dispatcher = new window.cf.EventDispatcher();
      dispatcher.addEventListener(window.cf.UserInputEvents.SUBMIT, handleUserSubmit, false);

      this.form = window.cf.ConversationalForm.startTheConversation({
        tags,
        options: {
          showProgressBar: true,
          eventDispatcher: dispatcher,
          robotImage,
          userInterfaceOptions: {
            controlElementsInAnimationDelay: 250,
            robot: {
              robotResponseTime: 0,
              chainedResponseTime: 400
            }
          },
          submitCallback: () => {
            console.log('Form submitted');
          }
        }

      });
      this.elem.appendChild(this.form.el);
    };

    this.intervalId = setInterval(initializeForm, 1000);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <style type="text/css">
            {`
            cf-input { background: transparent !important; }
            cf-input .inputWrapper { background: white !important; }
            .cf-progressBar { background-color: #8ac543 !important; }
            .conversational-form { background: transparent !important; }
            .conversational-form:before { background: transparent !important; }
          `}
          </style>
          <script type="text/javascript" src={withPrefix('conversational-form.min.js')}></script>
        </Helmet>
        <div ref={ref => this.elem = ref} />
      </Fragment>
    );
  }
}